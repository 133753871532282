import React from 'react';

const IconResume = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 60 60"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="your-class-name">
    <title>Resume</title>
    <rect x="2" y="2" width="39" height="7" rx="1" ry="1"></rect>
    <path d="M43.7 21.3l-2.8-6.6c-0.1-0.4-0.5-0.6-0.9-0.6s-0.7 0.2-0.9 0.6l-2.8 6.6c-0.2 0.2-0.3 0.4-0.3 0.7v31v6c0 0.6 0.4 1 1 1h6c0.6 0 1-0.4 1-1v-6V22c0-0.3-0.1-0.6-0.3-0.7z"></path>
    <rect x="15" y="20" width="12" height="2"></rect>
    <rect x="15" y="25" width="12" height="2"></rect>
    <rect x="6" y="31" width="15" height="2"></rect>
    <rect x="6" y="36" width="26" height="2"></rect>
    <rect x="6" y="41" width="26" height="2"></rect>
    <rect x="6" y="46" width="8" height="2"></rect>
    <rect x="6" y="51" width="11" height="2"></rect>
    <rect x="21" y="46" width="8" height="2"></rect>
    <rect x="21" y="51" width="11" height="2"></rect>
  </svg>
);

export default IconResume;
